import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/index"
import SEO from "../components/seo"

import StyledSectionBox from "../components/sectionBox/index"
import ImagesGrid from "../components/imagesGrid/index"
import { StyledContainer } from "../components/containers/index"

const QualityPageEn = ({ location, data: { background, images } }) => {
  return (
    <Layout location={location}>
      <SEO
        title="Frameway Industries Ltd. - Transfer Parts Manufacturer, Progressive Parts Manufacturer, Multi-slide Bending Parts Manufacturer"
        lang="en-US"
      />
      <StyledSectionBox
        background={background.childImageSharp.fluid}
        title="quality"
      />
      <StyledContainer>
        <h3>{images.edges[0].node.data.title.text}</h3>
      </StyledContainer>
      <ImagesGrid images={images.edges[0].node.data.image_gallery} />
    </Layout>
  )
}

export const query = graphql`
  {
    background: file(relativePath: { eq: "quality/quality-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    images: allPrismicQualityPage(filter: { lang: { eq: "en-us" } }) {
      edges {
        node {
          data {
            title {
              text
            }
            image_gallery {
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              image_description
            }
          }
        }
      }
    }
  }
`

export default QualityPageEn
